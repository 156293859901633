import { Link } from 'react-router-dom';

const NotFound = () => (
  <div>
    <h1 className='text-center mt-5'> Whoops! </h1>
    <h3 className="text-center my-2">This Page Doesn't Exist</h3>
    <Link to="/" className='d-block text-center my-5 text-dark notFoundLink'>Go To Homepage</Link>
  </div>
);

export default NotFound;