import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

import {galleryFood1, galleryFood2, galleryFood3, galleryFood4, galleryFood5, galleryFood6, galleryFood7, galleryFood8, galleryFood9, galleryFood10, galleryFood11, galleryFood12, galleryEvent1, galleryEvent2, galleryEvent3, galleryEvent4, galleryEvent5, galleryEvent6, galleryEvent7, galleryEvent8, galleryEvent9, galleryEvent10, galleryEvent11, galleryEvent12} from "../assets/Photos"

function Gallery() {

  var foodList = [galleryFood1, galleryFood2, galleryFood3, galleryFood4, galleryFood5, galleryFood6, galleryFood7, galleryFood8, galleryFood9, galleryFood10, galleryFood11, galleryFood12];

  function LightItem({list}) {
    return(
      list.map((path, i) => {
        return(
          <a href={path} key={i} >
            <img src={path} key={i} />
          </a>
        )
      })
    )
  }

  return (
  <div>
    <div className="container-fluid-m text-center my-5" id="galleryContainer">
      <div className="h2 mb-5"> Primizie Catering Event Gallery </div>
      <hr style={{margin: "1.5em auto", color: "gray" }} />
      

      <div className="accordion accordion-flush" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"     data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"     aria-controls="panelsStayOpen-collapseOne">
              <div className="text-center w-100 h5 color-light text-med"> 
              Food Gallery
                <p className="mt-3 text-center w-75">
                View our food gallery to see some examples of our delicious, fresh food and beautiful presentation.
                </p>
              </div>
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
            <LightGallery download={false} speed={500} plugins={[lgThumbnail]} thumbHeight="68px"elementClassNames="image-gallery container-fixed">
              <LightItem list={[galleryFood1, galleryFood2, galleryFood3, galleryFood4, galleryFood7, galleryFood5, galleryFood6, galleryFood8, galleryFood9, galleryFood10, galleryFood11, galleryFood12]} />
            </LightGallery>
            <div></div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"        data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
              <div className="text-center w-100 h5 color-light text-med"> 
              Decor Gallery
                <p className="mt-3 text-center w-75">
                Primizie Catering can help you plan and execute the event setup, according to your needs and preferences. Contact us to know more about our services in Austin, TX.
                </p>
              </div>
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
            <LightGallery download={false} speed={500} plugins={[lgThumbnail]} thumbHeight="68px"elementClassNames="image-gallery container-fixed">
              <LightItem list={[galleryEvent1, galleryEvent2, galleryEvent3, galleryEvent4, galleryEvent5, galleryEvent6, galleryEvent7, galleryEvent8, galleryEvent9, galleryEvent10, galleryEvent11, galleryEvent12]} />
            </LightGallery>
          </div>
        </div>
      </div>

      <hr style={{margin: "2.5em auto", color: "gray" }} />      
    </div>
  
</div>
  );
}

export default Gallery;