import {Link} from "react-router-dom";
import React from 'react';
import "../App.css";
import {card1, card2, card3, home1, home2} from '../assets/Photos';

function Home() {
    return (
      <div>
        <div  className="p-5 mb-4 bg-light home-cover">
          <div className='cover-text home-cover-text'>MAKE YOUR OCCASION MEMORABLE</div>
          <Link aria-current="page" to="/contact">
            <button className='cover-button cover-text'> CALL NOW </button>
          </Link>
        </div>
        <Blurb />
        <HomeCards />
      </div>
    );
  }

// Small about section for home page
function Blurb() {
  return (
    <div  className="blurb-padding mb-4 bg-light">
      <div  className="container-fluid text-center">
        <div  className="h2"> Primizie Catering Services </div>
        <div className='h4 fw-light my-4'>Pure Inspiration – Modern Innovation – Fresh Infusion</div>
        <hr style={{ width: "50vw", margin: "1.5em auto" }}/>
        <div className="d-flex">
          <div className="d-flex flex-column justify-content-center">
            <p>Primizie Catering is a full-service, off-premise catering company. Since its start in  late 2000, Primizie Catering has brought a new level of service and style to Austin by   serving innovative high quality food at events.</p>
            <p>
            "As beautiful as the dinner was displayed, it was just as amazingly delicious. Compliments  from the wedding guests continue to pour in to us." – Judy B. on Yelp</p>
          </div>
          <div className="ms-4 align-self-center">
            <img className="homeImgSm" alt="" src={home1}/>
          </div>
        </div>

        <div className="d-flex">
          <div className="align-self-center">
            <img className="homeImgSm me-4" alt="" src={home2}/>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <p>Translated, the Italian word Primizie means the first vegetables and fruits harvested in a season. This philosophy is applied while creating all of our dishes; whether they are personal takes on classic dishes or cuisines from around the globe. Our seasonal offerings are always crafted with the finest ingredients. All dishes are created from scratch and executed by trained chefs at the event site to ensure freshness and quality. We work directly with our clients to develop the perfect cuisine for their occasion.</p>
          </div>
        </div>
        <hr style={{ width: "75vw", margin: "1.5em auto 0px auto" }}/>
      </div>
    </div>
  );
}

class Card extends React.Component {
  render(){
    return (
      <div  className="col">
          <Link className="inline-link" aria-current="page" to={this.props.to} >
              <div  className="card">
                  <img src={this.props.path}  className="card-img-top" alt="..." />
                  <div className='card-text'>{this.props.text}</div>
              </div>
          </Link>
      </div>
    )
  }
}

function HomeCards() {
return (
  <div className="container-fluid text-center">
    <div  className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 card-container">
      <Card text={"Corporate Catering"} path={card1} to={"/corporate-catering"}/>
      <Card text={"Event Catering"} path={card2} to={"/event-catering"}/>
      <Card text={"Wedding Catering"} path={card3} to={"/wedding-catering"}/>
    </div>
  </div>
);
}

    
export default Home;