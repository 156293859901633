import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import About from "./Components/About"
import {Contact} from "./Components/Contact";
import Gallery from "./Components/Gallery";
import NotFound from "./Components/NotFound"

import CorperateCatering from "./Components/Catering/Corporate";
import EventCatering from "./Components/Catering/Event"
import WeddingCatering from "./Components/Catering/Wedding"

import "./App.css";
import logo from "./assets/logo.png"

function App() {
  return (
    <div className="App">
      <Header />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
          <Route path="/corporate-catering" element={<CorperateCatering />} />
          <Route path="/event-catering" element={<EventCatering />} />
          <Route path="/wedding-catering" element={<WeddingCatering />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound/>} />
      </Routes> 
      <Footer />
    </div>
  );
}

function Header() {
  return (
    <div  className="container-fluid">
      <header  className="d-flex justify-content-between py-3" id="test">
        <div  className="d-flex align-items-center mb-0 text-dark text-decoration-none">
          <img  className="me-auto p-2" id="headerLogo" src={logo} />
        </div>
        <div  className="d-flex align-items-center justify-content-center flex-wrap header-contact">
          <div className="row row-cols-auto">
            <svg  className="bi col my-auto" width="16" height="16">
              <use xlinkHref="#email" />
            </svg>
            <div className="col">info@primizieaustin.com</div>
          </div>
          <div  className="mx-3 small-hidden">|</div>
          <div className="row row-cols-auto">
            <svg  className="bi col my-auto" width="16" height="16">
              <use xlinkHref="#phone" />
            </svg>
            <div className="col">(512) 782-2373</div>
          </div>
        </div>
      </header>
    </div>
  );
}

function Footer() {
  return (
    <div className='bg-dark text-gray'>
      <div  className="container-fluid">
        <footer  className="d-flex flex-wrap justify-content-between align-items-center py-3 pb-5 mt-6">
          <div  className="col-md-4 d-flex align-items-center">
            <span  className="text-opacity-75" >Copyright © 2022 - Primizie Catering</span>
          </div>

          <ul  className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li  className="ms-3">
              <a  className="text-muted" href="https://www.instagram.com/primiziecatering/?hl=en" target="blank">
                <svg  className="bi" width="24" height="24">
                  <use xlinkHref="#instagram" />
                </svg>
              </a>
            </li>
            <li  className="ms-3">
              <a  className="text-muted" href="https://www.facebook.com/primiziecatering" target="blank">
                <svg  className="bi" width="24" height="24">
                  <use xlinkHref="#facebook" />
                </svg>
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
}

export default App;
