import {Link} from "react-router-dom";
import {corporate1} from "../../assets/Photos";

function CorperateCatering() {
    return(
        <div className="p-4 mb-4">
        <div className="container-fluid text-center">
          <div className="h2"> Corporate Catering Services </div>
          <div className="h5 fw-light my-4">
          We have planned corporate events for clients in a wide array of industries. 
          <Link className="inline-link" aria-current="page" to="/contact" > Call us now</Link> to inquire about our corporate catering services in Austin, TX.
          </div>
          <hr style={{ width: "75vw", margin: "1.5em auto" }} />
          <div className="content-links text-start color-light text-med">
            <img className="cateringImg" src={corporate1}/>
            <p>Primizie Catering is a great fit for all of your corporate catering needs. From client meetings to happy hours to customer appreciation gatherings, we can help you with putting together the perfect corporate event.</p>
            <p className="fst-italic">"Just want to make sure you don't think you have to be getting married to experience the wonderful food the Primizie cooks up!" <br />
            – <b>Nancy M.</b> on Yelp.</p>
            <p>We have launched a comprehensive delivery menu to complement our full service custom catering options. Hot, fresh food delivered to your office in a new line of eco packaging. Each meal is delivered with compostable plates and flatware, and all packaging is reusable, recyclable or compostable.</p>
          </div>
        </div>
      </div>
    )
}

export default CorperateCatering;
