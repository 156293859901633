import {about1, about2} from "../assets/Photos";

function About() {
    return (
      <div>
        <div className="p-5 mb-4 bg-light about-cover">
          <div className='cover-text about-cover-text'>THE RIGHT CATERING COMPANY</div>
        </div>
        <Blurb />
      </div>
    );
  }

  function Blurb() {
    return (
      <div className="p-4 mb-4">
        <div className="container-fluid text-center">
          <div className="h2"> About Primizie Catering </div>
          <div className="h5 fw-light my-4">
          Caterers in Austin, Texas
          </div>
          <hr style={{ width: "75vw", margin: "1.5em auto" }} />
          <div className="text-start color-light text-med mb-2">
            <p> &emsp;&ensp; The combination of traditional southern hospitality, old world Italian charm and modern culinary vision is the framework for Primizie Catering. Our business is about understanding and anticipating the vision of our clients and exceeding their expectations. Several Primizie Catering events have been featured in The Knot, Style Me Pretty, Tribeza, and Austin Monthly Home.</p>
          </div>
          <div className="about-text text-start color-light text-med mb-4">
            <img className="aboutImgL" alt="" src={about1}/>
            <h3> &nbsp; About Emily</h3>
            <p>Emily Horvath’s career has been committed to Austin hospitality. After graduating from James Madison University in Virginia with a BBA in hospitality and event management she headed to Texas on a new adventure.</p>
            <p>Wanting to work with and learn from the best, Emily began her career at Barton Creek Resort and Country Club. She worked in and assisted in managing many of the restaurants on the extensive property. Working with the members as a club dining manager is where Emily honed her expertise in client relations and enjoyed the experience of building lasting relationships with clients.</p>
            <p>Using her skills as a certified wedding coordinator, Emily excels at organizing and overseeing events to insure their flawless execution. She loves taking an event from inception to completion.</p>
          </div>
          <div className="about-text text-start color-light text-med mb-4">
            <img className="aboutImgR" alt="" src={about2}/>
            <h3> &nbsp; About Tray</h3>
            <p>Executive Chef Tray Horvath has been cooking professionally for twenty years. His culinary training began in New Orleans where he received a culinary arts degree and worked for the Hyatt Regency. Tray spent several years in New Orleans, which added a French and Cajun twist to his Texas roots.</p>
            <p>Always looking to expand his experiences, Tray moved to Savannah with the Hyatt. Savannah’s flair for “spicing up” traditional southern cooking and seafood added yet more depth to Tray’s culinary style.</p>
            <p>Originally from San Antonio, Tray’s return to Texas brought him to Austin. Working for Barton Creek Resort as the banquet chef, he had the opportunity to prepare a wide spectrum of meals ranging from traditional Texas barbeque to gourmet cuisine.</p>
          </div>
          <div className="text-start color-light text-med">
            <h3>Style Me Pretty</h3>
            <div className="content-links">
                <a href="https://www.stylemepretty.com/2011/03/04/texas-hill-country-wedding-by-ben-godkin-photo/" target="_blank" rel="noreferrer" >Texas Hill Country Wedding by Ben Godkin Photo</a><br />
                <a href="https://www.stylemepretty.com/2012/12/21/hill-country-wedding-at-camp-lucy-from-caroline-ben-photography/" target="_blank" rel="noreferrer">Austin Wedding from Caroline Joy Photography</a><br />
                <a href="http://www.stylemepretty.com/2013/06/27/austin-wedding-from-caroline-joy-photography-2/" target="_blank" rel="noreferrer">Hill Country Wedding at Camp Lucy from Caroline + Ben Photography</a><br />
                <a href="http://www.stylemepretty.com/2014/05/08/boho-eco-friendly-wedding/" target="_blank" rel="noreferrer">Boho Eco-Friendly Wedding</a><br />
                <a href="http://www.stylemepretty.com/2011/12/07/laguna-gloria-wedding-by-bz-events-ashley-garmon-photographers/" target="_blank" rel="noreferrer">Laguna Gloria Wedding by BZ Events + Ashley Garmon Photographers</a><br />
                <a href="http://www.stylemepretty.com/2012/02/23/laguna-gloria-wedding-by-half-orange-photography/" target="_blank" rel="noreferrer">Laguna Gloria Wedding by Half Orange Photography</a><br />
                <a href="http://www.stylemepretty.com/2012/12/14/austin-wedding-at-laguna-gloria-from-bloom-photography-2/" target="_blank" rel="noreferrer">Austin Wedding at Laguna Gloria from Bloom Photography</a><br />
            </div>
            

            <h3 className="mt-4">The Knot:</h3>
            <div className="content-links">
                <a href="https://www.theknot.com/marketplace/primizie-catering-austin-tx-274194#" target="_blank" rel="noreferrer">Check out our Real Weddings</a><br />
            </div>
          </div>
        </div>
      </div>
    );
  }

export default About;