import {Link} from "react-router-dom";
import {event1, event2, event3} from "../../assets/Photos"

function EventCatering() {
    return(
        <div className="p-4 mb-4">
        <div className="container-fluid text-center">
          <div className="h2">Event Catering Services</div>
          <div className="h5 fw-light my-4">
          From corporate or office meetings to weddings, brunch parties and fundraiser events, we are a full service event catering company in Austin, TX.
          <Link className="inline-link" aria-current="page" to="/contact" > Contact us</Link> to book our services.
          </div >
          <div className="h5 fw-light my-4">
          Every special event is unique. Are you having a birthday party, a shower, a graduation party or simply celebrating with friends? Let us work with you to create an event that reflects you or the guest(s) of honor.
          </div>
          <hr style={{ width: "75vw", margin: "1.5em auto" }} />
          <div className="content-links text-start color-light text-med">
            <p className="fst-italic">"We have been using Emily and the team for about 7 years. We have used them for both social and corporate events. We had a engagement party of sorts in our home last Saturday night for about 50 people and once again confirmed why we love Primizie Catering. Everything from the awesome selection and ease of set up to the presentation, food quality, friendly service and "like it never happened" take down and clean up is just perfect. And most importantly our guests ALWAYS comment on how they love the food at our events. We totally recommend Primizie!"</p>
            <p className="fst-italic">– <b className="my-3">Michael T.</b></p>
            <p>Primizie Catering encourages menu customization for each individual's special event. From food to style of service or a particular theme, menus can be designed to suit our client’s needs. Our catering menus are inspired by a wide spectrum of styles, tastes, textures and flavors from around the world.
            </p>
          </div>
          <div  className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 my-3 justify-content-center">
            <div className="col event-img">
              <img src={event1}  className="event-img" alt="..." />
            </div>
            <div className="col event-img">
              <img src={event2}  className="event-img" alt="..." />
            </div>
            <div className="col event-img">
              <img src={event3}  className="event-img" alt="..." />
            </div>
          </div>
        </div>
      </div>
    )
}

export default EventCatering;