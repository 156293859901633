import {Link} from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
      <div className="container-fluid">
        <button
          className="navbar-toggler row mx-3 align-items-center dropdown-mobile"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <div className="col ps-0 dropdown-mobile"> Menu </div>
          <span className="navbar-toggler-icon col dropdown-mobile"></span>
          
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/" >
                HOME
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/about">
                ABOUT
              </Link>
            </li>

            <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle" role="button" aria-expanded="false" onClick={firstDrop}>
                CATERING
              </div>
              <ul id="firstDropdown" className="dropdown-menu dropdown-menu-dark">
                <form>
                <li>
                  <Link className="dropdown-item" to="/corporate-catering">
                    CORPORATE CATERING
                  </Link>
                </li>

                <li>
                  <Link className="dropdown-item" to="/event-catering">
                    EVENT CATERING
                  </Link>
                </li>

                <li>
                  <Link className="dropdown-item" to="wedding-catering">
                    WEDDING CATERING
                  </Link>
                </li>
                </form>
              </ul>
            </li>

            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/gallery">
                GALLERY
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/contact">
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

function firstDrop() {
  document.getElementById("firstDropdown").classList.toggle("show");
}



// Closes dropdown menu when clicking anything but the menu
window.onclick = function(event) {
  if (!event.target.matches('.dropdown-toggle') && !event.target.matches('.drop') ) {
    var dropdowns = document.getElementsByClassName("dropdown-menu");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
  if (!event.target.matches('.dropdown-mobile') && !event.target.matches('.dropdown-toggle')) {
    console.log("test");
    var dropdown = document.getElementById("navbarSupportedContent");
    if (dropdown.classList.contains('show')) {
      dropdown.classList.remove('show');
    }
    
  }
}


document.addEventListener("DOMContentLoaded", function(){
  // make it as accordion for smaller screens
  if (window.innerWidth < 576) {
  
    // close all inner dropdowns when parent is closed
    document.querySelectorAll('.navbar .dropdown').forEach(function(everydropdown){
      everydropdown.addEventListener('hidden.bs.dropdown', function () {
        // after dropdown is hidden, then find all submenus
          this.querySelectorAll('.submenu').forEach(function(everysubmenu){
            // hide every submenu as well
            everysubmenu.style.display = 'none';
          });
      })
    });
  
    document.querySelectorAll('.dropdown-menu a').forEach(function(element){
      element.addEventListener('click', function (e) {
          let nextEl = this.nextElementSibling;
          if(nextEl && nextEl.classList.contains('submenu')) {	
            // prevent opening link if link needs to open dropdown
            e.preventDefault();
            if(nextEl.style.display === 'block'){
              nextEl.style.display = 'none';
            } else {
              nextEl.style.display = 'block';
            }
  
          }
      });
    })
  }
  // end if innerWidth
  }); 

  export default Navbar;