import React, { useRef } from "react";
import InputMask from "react-input-mask";
import emailjs from "@emailjs/browser";

export const Contact = () => {
  const form = useRef();
  const ref = useRef();

  const sendEmail = () => {
    emailjs.sendForm(
        "service_r8ksp8a",
        "template_loi2qln",
        form.current,
        "__6res-YJApZquPZw"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Email sent")
          showSuccess()
        },
        (error) => {
          console.log("Error")
          console.log(error.text);
          showFailed()
        }
      );
  };

  function showSuccess() {
    document.getElementById('contact-form').classList.add("submitted")
    document.querySelectorAll('.successMsg').forEach( element => {
      element.classList.add("submitted")
    })
  }
  function showFailed() {
    document.getElementById('contact-form').classList.add("submitted")
    document.querySelectorAll('.failedMsg').forEach( element => {
      element.classList.add("submitted");
      console.log(element);
    })
  }

  function checkInput(e) {
    e.preventDefault()
    var forms = document.querySelectorAll('#contact-form')
    // Loop over our form and prevent submission
    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        var inputs = document.querySelectorAll('.required')
        inputs.forEach(element => {
          var values = element.value

          if (values) {
            element.classList.add("is-valid");
            element.classList.remove("is-invalid");
          } else {
            element.classList.remove("is-valid");
            element.classList.add("is-invalid");
          }
        })
        
        const phoneInput = document.querySelector("#phoneNum");
        var phoneValue = phoneInput.value.replace(/\D/g, "");
        if (phoneValue.length < 11) {
          phoneInput.classList.remove("is-valid");
          phoneInput.classList.add("is-invalid");
        } else {
          phoneInput.classList.add("is-valid");
          phoneInput.classList.remove("is-invalid");
        }
        var phoneValidity = document.querySelector("#phoneNum").classList[1] === "is-valid";

        const dateInput = document.querySelector("#eventDate");
        const dateInput2 = document.querySelector("#eventDate2");
        var dateYear = dateInput.value.substr(0,4);
        var dateMonth = dateInput.value.substr(5,2)
        var dateDay = dateInput.value.substr(8,2)
        var correctFormat = `${dateMonth}/${dateDay}/${dateYear}`
        dateInput2.value = correctFormat

        console.log("All text forms filled out?", form.checkValidity())
        console.log("Phone form filled out?", phoneValidity)
        if (!form.checkValidity() || !phoneValidity) {
          e.stopPropagation()
        } else {sendEmail()}
        
      })
  }

  return (
    <div>
      <div className="p-5 mb-4 bg-light contact-cover">
        <div className='cover-text contact-cover-text'>LET US MAKE YOUR DAY SPECIAL</div>
      </div>
      <div className="p-4 mb-4">
        <div className="container-fluid text-center">
          <div className="h2"> Contact Primizie Catering </div>
          <div className="h5 fw-light my-4">
            Event Catering Company Serving Austin and Central Texas
          </div>
          <hr style={{ width: "75vw", margin: "1.5em auto" }} />
          <div className="row gx-5 text-start color-light">
            <div className="info-col">
              <div className="row row-cols-1">

                <h4 className="col mb-4">Contact Info:</h4>

                <p className="col mb-4 nowrap">
                  <svg className="me-4" width="25" height="25">
                    <use xlinkHref="#email" />
                  </svg>
                  <a
                    href="https://mail.google.com/mail/?view=cm&to=info@primizieaustin.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u>info@primizieaustin.com </u>
                  </a>                  
                </p>

                <p className="col mb-4 nowrap">
                  <svg className="me-4" width="25" height="25">
                    <use xlinkHref="#phone" />
                  </svg>
                  <a href="tel:5127822373">
                    <u>(512) 782-2373 </u>
                  </a>
                </p>
              </div>
            </div>

            <div className="message-col">
              <h4 className="mb-4">
                Message Us:
              </h4>
              <div id="formContainer"className="position-relative">
                <form id="contact-form" onSubmit={checkInput} ref={form} noValidate>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="md-form mb-0">
                                <label>First name</label>
                                <input type="text" name="first_name" className="form-control required" placeholder="First Name" required/>
                                <div className="valid-feedback">Looks Good!</div>
                                <div className="invalid-feedback">Please fill out this field.</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="md-form mb-0">
                                <label>Last Name</label>
                                <input type="text" name="last_name" className="form-control required" placeholder="Last Name" required/>
                                <div className="valid-feedback">Looks Good!</div>
                                <div className="invalid-feedback">Please fill out this field.</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="md-form mb-0">
                                <label>Email</label>
                                <input type="text" name="email" className="form-control required" placeholder="name@example.com" required/>
                                <div className="valid-feedback">Looks Good!</div>
                                <div className="invalid-feedback">Please enter a valid email.</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="md-form mb-0">
                                <label>Phone Number</label>
                                <InputMask type="tel" name="phone" id="phoneNum" className="form-control" mask="+1 (999) 999-9999" placeholder="( __ ) ___ - ____" required/>
                                <div className="valid-feedback">Looks Good!</div>
                                <div className="invalid-feedback">Please enter a valid phone number.</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="md-form mb-0">
                                <label>Event Date</label>
                                <InputMask id="eventDate" ref={ref} className="form-control required" placeholder="mm/dd/yyyy" max="9999-12-31"
                                onFocus={(e) => (e.target.type = "date")} required/>
                                <div className="valid-feedback">Looks Good!</div>
                                <div className="invalid-feedback">Please enter a valid date.</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="md-form mb-0">
                                <label>Guest Count</label>
                                <input type="text" name="guests" className="form-control required" placeholder="Guest Count" required/>
                                <div className="valid-feedback">Looks Good!</div>
                                <div className="invalid-feedback">Please enter a valid guest count.</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div className="md-form mb-0">
                                <label>Subject</label>
                                <input type="text" id="subject" name="subject" placeholder="Subject line" className="form-control required" required/>
                                <div className="valid-feedback">Looks Good!</div>
                                <div className="invalid-feedback">Please enter a subject line. </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2  ">
                        <div className="col-md-12">
                            <div className="md-form">
                                <label>Your message</label>
                                <textarea type="text" id="message" name="message" rows="4" className="form-control md-textarea required" placeholder="Please provide any relevant information, such as location or time of event" required></textarea>
                                <div className="valid-feedback">Looks Good!</div>
                                <div className="invalid-feedback">Please add a message. </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-md-left">
                    <button className="btn btn-outline-secondary col-2  " type="submit" value="Send"> Submit </button>
                    </div>
                  <input type="text" name="date" id="eventDate2" style= {{display: "none", visibility: "hidden"}}/>
                </form>

                {/* Displayed after submition, relays that form sent successfully or failed */}
                <svg className="successMsg" width="100" height="100">
                  <use xlinkHref="#checkMark" />
                </svg>
                <h1 className="successMsg">Thank You!</h1>
                <h5 className="successMsg">Your message was sent. We will get back to you shortly.</h5>

                <svg className="failedMsg" width="100" height="100">
                  <use xlinkHref="#xMark" />
                </svg>
                <h1 className="failedMsg">Error</h1>
                <h5 className="failedMsg">Your message wasn't sent. Please try again later.</h5>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};