import {Link} from "react-router-dom";
import { wedding1 } from "../../assets/Photos";

function WeddingCatering() {
    return(
        <div className="p-4 mb-4">
        <div className="container-fluid text-center">
          <div className="h2">Wedding Catering Services</div>
          <div className="h5 fw-light my-4">
          Primizie Catering can help you plan your wedding reception.
          <Link className="inline-link" aria-current="page" to="/contact" > Contact us</Link> to know more about our wedding catering services in Austin, TX.
          </div >
          <hr style={{ width: "75vw", margin: "1.5em auto" }} />
          <div className="content-links text-start color-light text-med">
            <img className="cateringImg" src={wedding1}/>
            <p>Primizie Catering offers fresh, modern catering infused with your inspiration. Tell us about you, your guests and your wedding and we will work with you to create a custom celebration. Our highly-trained culinary team, knowledgeable and accommodating catering sales managers and our top-of-the line service team are here to help you achieve your vision. If you do not have a set vision, we can assist with that as well. 
            <Link className="inline-link" id="weddingLink" aria-current="page" to="/contact" > Give us a call today.</Link>
            </p>
            <p className="fst-italic">"I had such a positive experience working with Amy at Primizie. Primizie catered my wedding the weekend of August 24, 2013, and from the get go, Amy was a joy to work with. I tend to be type A and can over manage situations however after meeting Amy, I was able to relax and knew that everything would be executed flawlessly. I appreciate Primizie's flexibility with respect to budget and last minute changes as well as the laid back-ness of the entire staff. It really made any stress that I had disappear. I received (and continue to receive) so many compliments on the food and excellent service and all of the credit is due to Primizie. Thank you to Amy and to the Primizie staff for helping my wedding day be incredible!"</p>
            <p className="fst-italic">– <b>Eva G.</b> on Yelp.</p>
          </div>
        </div>
      </div>
    )
}

export default WeddingCatering;